<template>
  <div class="announce-config">
    <el-tabs style="margin-bottom: 20px" value="config" @tab-click="handleTabClick">
      <el-tab-pane :label="tab.label" :name="tab.name" v-for="(tab, index) in tabArr" :key="index">
      </el-tab-pane>
    </el-tabs>
    <div class="list-content" v-loading="loading">
      <div class="config-item">
        <div class="config-title">审核配置</div>
        <div class="config-content">
          <el-form class="small-form" size="small" label-width="70px" label-position="left">
            <el-form-item label="自动通过" style="margin-bottom: 6px">
              <el-switch v-model="config.is_auto_through" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <div class="form-tip">
              <span class="el-icon-info" style="font-size: 15px; color:#C0C4CC"></span>
              <span>开启后 ，当公示期满时自动通过审核</span>
            </div>
          </el-form>
        </div>
      </div>
      <div class="config-item">
        <div class="config-title">公示期时间配置</div>
        <div class="config-content">
          <el-form class="small-form" label-width="120px" label-position="left">
            <el-form-item label="正式会员公示期">
              <el-input-number size="medium" :min="1" v-model="config.member_time"></el-input-number>
              <span class="suffix">天</span>
            </el-form-item>
            <el-form-item label="职务晋升公示期">
              <el-input-number size="medium" :min="1" v-model="config.position_time"></el-input-number>
              <span class="suffix">天</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="config-item">
        <div class="config-title">申请时间配置</div>
        <div class="config-content">
          <el-form class="small-form" label-width="160px" label-position="left">
            <el-form-item label="正式会员公示期不通过后">
              <el-input-number size="medium" :min="1" v-model="config.member_no_through_time"></el-input-number>
              <span class="suffix">天后可以重新申请</span>
            </el-form-item>
            <el-form-item label="职务晋升公示期不通过后">
              <el-input-number size="medium" :min="1" v-model="config.position_no_through_time"></el-input-number>
              <span class="suffix">天后可以重新申请</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <fixed-action-bar>
      <el-button type="primary" size="medium" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import {getOtherConfig, saveOtherConfig} from "../../api/announce/other-config";
  import FixedActionBar from "../../../../base/layout/FixedActionBar";

  export default {
    components: {FixedActionBar},
    data() {
      return {
        loading: true,
        tabArr: [{label: "公示列表", name: "list"}, {label: "其他配置", name: "config"}],
        config: {
          is_auto_through: 0,
          member_no_through_time: 604800,
          member_time: 604800,
          position_no_through_time: 604800,
          position_time: 604800,
        }
      }
    },
    created() {
      getOtherConfig().then(res => {
        this.config = res.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    methods: {
      saveConfig() {
        this.loading = true;
        saveOtherConfig({
          config: this.config
        }).then(res => {
          this.$message.success(res.msg)
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },
      handleTabClick(e) {
        if (e.paneName === 'list') {
          this.$router.push({name: "CycUserPublicList"})
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.announce-config {
  .config-item + .config-item {
    margin-top: 79px;
  }
  .config-item {
    padding-top: 20px;
    .config-title {
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      border-left: 3px #3479ef solid;
    }
    .config-content {
      margin-top: 32px;
      padding-left: 11px;
      .switch-config {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .form-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 14px;
          margin-right: 15px;
        }
      }
      .form-tip {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606266;
        line-height: 12px;
        span + span {
          margin-left: 4px;
        }
      }
      .suffix {
        line-height: 34px;
        margin-left: 12px;
        color: rgba(0, 0, 0, 0.85);
      }

      .el-input-number {
        max-width: 140px;
      }
    }
  }
}
</style>
