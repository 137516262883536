import api from '@/base/utils/request';

export const getOtherConfig = () => {
  return api({
    url: '/admin/cyc/user_public/getPublicOtherConfig',
    method: 'post'
  })
}

export const saveOtherConfig = (data) => {
  return api({
    url: '/admin/cyc/user_public/setPublicOtherConfig',
    method: 'post',
    data
  })
}
